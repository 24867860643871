<template>
  <div class="route2">

    <!-- <img :src="iconPath" alt=""> -->
    <div class="content2">
      <component :is="textSection"></component>
      <img :src="imgPath" alt="">
    </div>
  </div>
</template>
<script>
export default {
  props: ['textSection', 'imgPath', 'iconPath'],
}
</script>
<style scoped>
.route2 {
  margin-top: 100px;
  padding-left: 300px;
  padding-right: 300px;
  /* padding-right: 150px; */
  /* background-color: red; */
}

.route2 img {
  height: 96px;
  width: 96px;
}

.route2 h1 {
  color: #357C2C;
  font-family: Raleway;
  font-size: 42px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
  margin-left: 74px;
  /* 52.5px */
}

.content2 {
  margin-left: 63px;
  display: flex;
  /* background-color: aqua; */
}

.content2 img {
  min-width: 464px;
  min-height: 300px;
  margin-left: 100px;
}

@media screen and (max-width: 560px) {
  .content2 {
    /* background-color: #357C2C; */
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-left: 0;
  }

  .content2 img {
    min-width: 320px;
    min-height: 200px;
  }
}

@media screen and (max-width: 1400px) {
  .content2 {
    /* background-color: #357C2C; */
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    max-width: 480px;
    margin-left: 0;
  }

  .content2 img {
    margin-left: 0;
  }

}
</style>
